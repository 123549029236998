import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "AmazonConnect" },
    meta: {
      public: true,
    },
  },
  {
    path: "/amazonConnect",
    name: "AmazonConnect",
    component: () => import("@/views/AmazonConnect.vue"),
    meta: {
      public: true,
      layout: "auth"
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
