import { extend, configure } from "vee-validate";
import { required, email, min, max, numeric, length } from "vee-validate/dist/rules";
import { i18n } from "./i18n";

configure({
    defaultMessage: (field, values) => {
        return i18n.t(`validation.${values._rule_}`, values);
    }
});

// Install required rule and message.
extend("required", required);

// Install required rule and message.
extend("length", length);
// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

// Install max rule and message.
extend("max", max);

extend("numeric", numeric);

// date validation rule
extend("phoneNumberRule", {
    // eslint-disable-next-line no-unused-vars
    validate: value => {

        if (value.length === 12 && value.startsWith("+1")) { return true }
        if (value.length === 11 && value.startsWith("1")) { return true }
        if (value.length === 10 && !(value.startsWith("1") || value.startsWith("+"))) { return true }

        return i18n.t("customValidationRules.notValidPhoneNumber")
    }
});

extend( 'twilioCode', {
    validate: value => {
        if ( value.length != 4) {
            return i18n.t('customValidationRules.twilioCode')
        }
        return true
    }
})
