// @ts-ignore
import {contact_info} from "@/utils/contact-info-dictionary";

export const ENGLISH_TRANSLATIONS = {
    pages: {
        statements: {
            title: "Statements",
            remainingStatementBalance: "Remaining Statement Balance",
            minimumDue: "Minimum Due",
            paymentDueBy: "Payments Due by",
            autoPay: "Auto Pay",
            makeAPayment: "Make a Payment",
            nothingDueNow: "You have nothing due right now. You can make a payment towards your current balance to get ahead.",
            nothingCurrentlyDue:"Nothing Currently Due",
            FullBalance:"Full Balance",
            StatementBalance:"Statement Balance",
            Minimum:"Minimum",
            CustomAmount:"Custom Amount",
            Cancel:"Cancel",
            paidOn:"Paid on"
        },
        activity: {
            title: "Activity",
            Available: "Available",
            NoTransactionYet: "No Transaction Yet",
            anticipation: "The anticipation is overwhelming,",
            makePayment: "make a payment to start",
            Today: "Today",
            Pending:"Pending"
        },
        MakePayment:{
            MakePayment:"Make Payment",
            PayNow:"Submit"
        },
        PrivacyPolicy:{
            PrivacyPolicy:"Privacy Policy"
        },
        TermsCondition:{
            TermsCondition:"Terms & Condition"
        },
        CreditAgreement:{
            CreditAgreement:"Credit Agreement"
        },
        card: {
            YourCard: "Your Card",
            PaymentSettings: "Payment Settings",
            PersonalDetail: "Personal Details",
            NotificationSettings: "Notification Settings",
            LogOut: "Log Out"

        },
        yourcard: {
            ReportLostorStolen: "Report Lost or Stolen",
            YourCard: "Your Card",
            CreditLimit: "Credit Limit",
            APR: "APR",
            PolicyandTerms: "Policies and Agreements",
            PrivacyPolicy: "Privacy Policy",
            TermsCondtions: "Terms & Condtions",
            CreditAgreement: "Credit Agreement",
            AvailableCredit: "Available Credit",
        },
        login: {
            phonePlaceholder: "Enter Phone Number",
            codeHeadline: "Confirmation code sent to",
            codePlaceholder: "Enter Confirmation Code",
            continue: "Continue"
        },
        setPasscode: {
            phonePlaceholder: "Enter 4 digits",
            createPasscode:"Create a passcode",
            continue: "Continue",
            verifyPasscodeHtml : "Looking good 👍<br/> Type it again to confirm"
        },
        SSNVerification: {
            Last4ofSSN: "Last 4 of SSN",
            DateOfBirth: "Date of Birth",
            SSNTitle: "To set up your passcode, please confirm your identity.",
            dob: "MM/DD/YYYY",
        },
        CardActivation:{
            title: "Card Activation",
          descTitle : "To activate your card, enter the last 4 digits of your new Aven card",
          Last4ofCardNumber :"Last 4 digits of your card",
          continue:"continue",
            activate: "Activate",
          ACTIVATION_ATTEMPTS_REACHED :"Activation attempts reached",
          ACTIVATION_DATA_INVALID :"Activation data invalid",

        },
        AddBankAccount:{
            AddBanksAccount:"Add Bank Account",
            AccountHolderName:"Account Name",
            AccountHolderNumber:"Account Number",
            Nickname:"Account Nickname (ex. Chase Checking)",
            PhoneNumber:"Phone Number",
            AccountType:"Account Type",
            RoutingNumber:"Routing Number",
            State:"State",
            Zipcode:"Zipcode",
            AddBankAccount:"Add Bank Account",
            PersonalChecking:"Personal Checking",
            PersonalSavings:"Personal Savings",
            BusinessChecking:"Business checking",
            BusinessSavings:"Business Savings",
            YourDetails:"Your Details",
            AccountDetails:"Account Details"
        },
        appHeader:{
            HELP:"HELP"
        },
        accountbalance:{
            AccountBalance:"Account Balance"
        },
        dashboard:{
            Activity:"Activity",
            Statements:"Statements",
            Card:"Card"
        },
        reportlost: {
            ReportLostorStolen: "Report Lost or Stolen",
            Donotworry: "Don’t worry, we can cancel your current card and issue you a new one right away.",
            withoutpermission: "If someone used your card without your permission, please call us right way.",
            callus: "Call Us"
        },
        personalInformation: {
            PersonalInformation: "Personal Details",
            Name: "Name",
            Contact: "Contact Information",
            MobileNumber: "Mobile Number",
            Email: "Email",
            Address: "Address",
            UpdateAddressInfo:"For security reasons please call us at ",
            UpdateAddressInfo2:"to change any of your personal information",
            IVRNumber:"1-800-615-4101"
        },
        PaymentSetting: {
            PaymentSettings: "Payment Settings",
            PaymentSource: "Payment Source",
            ChaseChecking: "Chase Checking",
            AutoPay: "AutoPay",
            AutoPaySetting: "AutoPay Setting",
            Amount:"Amount",
            PaymentSourceWarning:"Please set the default payment source to AutoPay",
            AutoPayAmountWarning: "Please set the amount to AutoPay",
            Ok : "OK"
        },
        PaymentSource: {
            PaymentSource: "Payment Source",
            BillingAccountNotFound:"No Payment Sources",
            AddBankDebit:"Please add a Debit Card or Bank Account",
            ChaseChecking: "Chase Checking",
            Cardending: "Card ending ",
            AddPaymentMethod: "Add Payment Method",
            AddBankAccount:"Add Bank Account",
            DeleteFundingSource:"Delete Funding Source?",
            Delete:"Delete"
        },
        AutoPayAmount: {
            AutoPayAmount: "AutoPay Amount",
            CurrentBalance: "Current Balance",
            StatementBalance: "Statement Balance",
            MinumumBalance: "Minumum Balance"
        },
        NotificationSettings: {
            NotificationSettings: "Notification Settings",
            SendWhen:"Send When",
            NewTransaction:"New Transaction",
            NewStatement:"New Statement",
            PaymentMade:"Payment Made",
            PushNotification:"Notifications"

        },
        CancelBalanceTransfer: {
            Title: "Cancel Balance Transfer",
            ConfirmationNumber: "Confirmation Number",
            SubmitButton: "Cancel Balance Transfer",
        },
        CardReplacement: {
            Title: "Card Replacement",
            SubmitButton: "Replace Card",
            DeliveryMechanism: "Delivery Mechanism",
            ContactName: "Contact Name",
            ContactAddressLine1: "Line 1",
            ContactAddressLine2: "Line 2",
            ContactAddressCity: "City",
            ContactAddressState: "State",
            ContactAddressPostalCode: "Postal Code",
            ContactAddressCountry: "Country",
            ContactAddressLine1Placeholder: "Street Name",
            ContactAddressLine2Placeholder: "Unit/Suite/Apt",
            ContactAddressStatePlaceholder: "E.g. CA",
        },
        EndSession: {
            Title: "Have you copied this session id into Live Vox console?",
            SubmitButton: "Yes, End Session"
        },
        CopySessionId: {
            Title: "Copy this Session Id into Live Vox console",
            SubmitButton: "Ok, I copy pasted it"
        },
        balanceTransfer: {
            title: 'Balance Transfer',
            balanceTransferSuccessTitle: 'Balance Transfer Submitted',
            balanceTransferFailureTitle: 'Balance Transfer Failed',
            balanceTransferSuccessContent: 'Your balance transfer has been successfully scheduled. Confirmation Number: {confirmationNumber}.',
            balanceTransferFailureContent: 'Your balance transfer request has failed. Please contact customer service for assistance.',
            balanceTransferSuccessButton: 'Done',
            balanceTransferFailureButton: 'Retry',
            balanceTransferPromotionalPeriodFailTitle: 'Balance Transfer outside of promotional period',
            balanceTransferPromotionalPeriodFailContent: 'Sorry, you cannot perform a Balance Transfer because this account is beyond the promotional period for Balance Transfers!',
            balanceTransferPromotionalPeriodFailButton: 'Go back',
            balanceTransferSecondaryNotAllowed: 'Secondary card holders are not allowed to Balance Transfer. Please inform the customer that only a Primary card holder can Balance Transfer at this time.',
            error: {
                title: 'Balance Transfer Error',
                genericError:
                    'An error came up while processing Balance Transfer request. Please alert engineering team.',
                invalidAccountNumberFail: {
                    title: 'Credit Card Error',
                    subTitle: 'Sorry, the credit card number you entered could not be validated.',
                },
                btForbiddenToCustomer:
                    'Unfortunately, Balance Transfers can only be initiated by the primary cardholder or co-applicant. Please have the primary cardholder or co-applicant perform the Balance Transfer.',
                invalidAmountFail: 'The amount you have entered is invalid. Please correct the amount and submit your Balance Transfer again.',
                bankNotSupported:
                    'Sorry, we do not currently support balance transfers from this credit card. Instead, please advice customer to use our Cash Out feature from the App to send money to your bank account to pay the card.',
                duplicateRequestError: {
                    title: 'Balance Transfer Exists',
                },
            },
        }
    },
    header:{
        question: "Have a Question?",
        description: "Give us a call if you have any questions or comments.",
        call:`<a class="text-white" href='tel:${contact_info.phone}'>Call</a>`,
        help:"Help",
        callUs:"1-800-615-4101"
    },
    dashBoard:{
        call:`<a href='tel:${contact_info.phone}' style="color: black">1-800-615-4101</a>`,
    },
    global: {
        // Page not foun
        pageNotFound: {
            header: "Page not found",
            subHeader:
                "Sorry, but the page you were looking for does not seem to exist. To apply, visit the link below.",
            cta: "Get started"
        },
        loadingMessage: {
            loading: "Loading...",
            verifying: "Verifying...",
            saving: "Saving...",
        },
        cta: {
            continue: "Continue",
            next: "Next"
        },
        errors: {
            generic: "Sorry, there was a problem with the network. Please try again.",
            confirmAddress: "Could not confirm address. Please check your entry"
        }
    },
    customValidationRules: {
        twilioCode: 'Code must be exactly 4 digits',
        notValidPhoneNumber: 'Not a valid phone number',
    },
    validation: {
        alpha: "May only contain alphabetic characters",
        alpha_num: "May only contain alpha-numeric characters",
        alpha_dash: "May contain alpha-numeric characters as well as dashes and underscores",
        alpha_spaces: "May only contain alphabetic characters as well as spaces",
        between: "Must be between {min} and {max}",
        confirmed: "Confirmation does not match",
        digits: "Must be numeric and exactly contain {length} digits",
        dimensions: "Must be {width} pixels by {height} pixels",
        email: "Must be a valid email",
        excluded: "Is not a valid value",
        ext: "Is not a valid file",
        image: "Must be an image",
        integer: "Must be an integer",
        length: "Must be {length} characters long",
        max_value: "Must be {max} or less",
        max: "May not be greater than {length} characters",
        mimes: "Must have a valid file type",
        min_value: "Must be {min} characters or more",
        min: "Must be at least {length} characters",
        numeric: "May only contain numeric characters",
        oneOf: "Is not a valid value",
        regex: "Format is invalid",
        required_if: "This field is required",
        required: "This field is required",
        size: "Size must be less than {size}KB"
    },
};
