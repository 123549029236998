/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughtout the application
 * Default, this is just using the LocalStorage
 */
export class AppStorage {
  constructor(private readonly storage: Storage) {
    this.storage = storage || window.localStorage;

    /** Is storage is supported or not */
    if (!this.isSupported()) {
      throw new Error("Storage is not supported by browser!");
    }
  }

  setItem(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  getItem(key: string) {
    return this.storage.getItem(key);
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

  /**
   * @description Check for storage support
   * @returns {boolean} supported
   * * */
  isSupported() {
    let supported = true;

    if (!this.storage) {
      supported = false;
    }

    return supported;
  }
}

/**
 * Creating the instance of storage. Default will be localStorage
 * but if you want to create instance for session storage then pass window.sessionStorage as parameter
 */
const appLocalStorage = new AppStorage(window.localStorage);
const appSessionStorage = new AppStorage(window.sessionStorage);

const localStorageKey = {
  cardType: "cardType",
  cardTypeVariation: "cardTypeVariation",
  sessionId: "sessionId",
  sessionAccessJWT: "sessionAccessJWT",
  nationalNumber: "nationalNumber",
  jwtToken: "jwtToken",
  isMobileAppWebView: "isMobileAppWebView",
  tokenId: "token-id",
  fundingSourceSubType:"fundingSourceSubType",
  fundingNickName:"fundingNickName",
  autopayCategory:"payCategory",
  maskCard:"maskCard",
  deviceGuid: 'deviceGuid',
  platform:'platform',
  isPasscodeSet:'isPasscodeSet',
  csrName: 'csrName',
  customerName: 'customerName',
  phoneNumber: 'phoneNumber',
};

export { appLocalStorage, appSessionStorage, localStorageKey };
