import Vue from 'vue';
import { appSessionStorage, localStorageKey } from "./storage";
import router from "@/router";

Vue.config.errorHandler = (err, vm, info) => {
    console.log(`Vuejs error: ${JSON.stringify({ err, vm, info })}`);
};

window.onerror = function(message, source, lineno, colno, error) {
    console.log(`window.onerror: ${JSON.stringify({ message, source, lineno, colno, error })}`);
};

const ApiErrorHandler = function (error) {
    // Can eventually add other errors and convert to a switch statement
    if (error?.response?.status === 401) {
        appSessionStorage.removeItem(localStorageKey.jwtToken);
        router.push({ path: "Login", query: { reason: 'authorization' }}).catch(error => console.log(JSON.stringify(error)));
    } else {
        console.error(error);
    }
}
export { ApiErrorHandler }
